<template>
  <v-sheet dark class="background">
    <div class="px-10 py-6 d-flex flex-column">
      <v-btn class="mt-6" absolute left icon dark to="/">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <img
        class="my-6 mx-auto"
        style="max-width: 110px"
        src="/img/Logo.svg"
        alt="Baccarat Quest"
      />
      <div class="text-center" v-html="ui('remerciements')" />
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "About",
};
</script>

<style>
.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background: url("/img/Background.svg");
  background-size: cover;
  background-position: center center;
}
</style>
